<template>
    <div>{{ statusText }}</div>
</template>

<script>
    import auth from "../../auth";
    import request from "../../repo/request";

    export default {
        name: "LoginAdmin",
        data() {
            return {
                statusText: '登录中，请稍后...'
            }
        },
        mounted() {

            this.checkToken()

        },
        methods: {
            checkToken() {

                let token = this.$route.query.token
                let action = this.$route.query.action

                request.post('/collocation/auth/check', {token}, (data) => {

                    if (data.code !== 0) {
                        this.statusText = '登录失败，请联系管理员.'
                        return
                    }

                    auth.remember(token, data.data.info)

                    if (action === 'edit-scheme') {

                        let id = this.$route.query.id
                        this.$router.push('/dps/deck?outfit_id=' + id);

                    } else {
                        this.$router.push('/dps/deck');
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>